<template>
  <v-row>
    <v-col cols="8">
      <v-text-field
        id="street"
        :model-value="address.street"
        label="Straße"
        hide-details="auto"
        data-cy="street"
        :readonly="readonly"
        bg-color="#fff"
        variant="outlined"
        @update:model-value="$emit('update:modelValue', { ...address, street: $event })"
      />
    </v-col>

    <v-col cols="4">
      <v-text-field
        id="street_number"
        :model-value="address.street_number"
        label="Nr"
        hide-details="auto"
        data-cy="streetNumber"
        bg-color="#fff"
        :readonly="readonly"
        variant="outlined"
        @update:model-value="$emit('update:modelValue', { ...address, street_number: $event })"
      />
    </v-col>

    <v-col cols="12">
      <v-text-field
        id="addition"
        :model-value="address.addition"
        label="Addresszusatz (optional)"
        data-cy="addition"
        hide-details="auto"
        variant="outlined"
        bg-color="#fff"
        :readonly="readonly"
        @update:model-value="$emit('update:modelValue', { ...address, addition: $event })"
      />
    </v-col>

    <v-col cols="4">
      <v-text-field
        id="zip"
        :model-value="address.zip"
        label="Postleitzahl"
        hide-details="auto"
        data-cy="zip"
        bg-color="#fff"
        :readonly="readonly"
        variant="outlined"
        @update:model-value="setCity($event)"
      />
    </v-col>

    <v-col cols="8">
      <v-text-field
        id="city"
        :model-value="address.city"
        label="Stadt"
        hide-details="auto"
        data-cy="city"
        bg-color="#fff"
        :append-icon="cityIcon"
        :readonly="readonly"
        variant="outlined"
        @update:model-value="$emit('update:modelValue', { ...address, city: $event })"
      />
    </v-col>

    <v-col cols="6">
      <v-select
        :model-value="address.state_code"
        label="Bundesland / Kanton"
        :items="selectedStates"
        item-title="name"
        hide-details="auto"
        bg-color="#fff"
        item-value="code"
        data-cy="states"
        :readonly="readonly"
        variant="outlined"
        @update:model-value="$emit('update:modelValue', { ...address, state_code: $event })"
      />
    </v-col>
    <v-col cols="6">
      <v-select
        :model-value="address.country_code"
        label="Land"
        :items="countries"
        hide-details="auto"
        item-title="name"
        item-value="code"
        bg-color="#fff"
        data-cy="countries"
        :readonly="readonly"
        variant="outlined"
        @update:model-value="updateStatesDropdown($event)"
      />
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    modelValue: {
      type: Object,
      default: () => {
        return { country_code: 'DE' }
      },
    },
    readonly: {
      type: Boolean,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      cityIcon: null,
      states: [
        [
          { code: 'BW', name: 'Baden-Württemberg' },
          { code: 'BY', name: 'Bayern' },
          { code: 'BE', name: 'Berlin' },
          { code: 'BB', name: 'Brandenburg' },
          { code: 'HB', name: 'Bremen' },
          { code: 'HH', name: 'Hamburg' },
          { code: 'HE', name: 'Hessen' },
          { code: 'MV', name: 'Mecklenburg-Vorpommern' },
          { code: 'NI', name: 'Niedersachsen' },
          { code: 'NW', name: 'Nordrhein-Westfalen' },
          { code: 'RP', name: 'Rheinland-Pfalz' },
          { code: 'SL', name: 'Saarland' },
          { code: 'SN', name: 'Sachsen' },
          { code: 'ST', name: 'Sachsen-Anhalt' },
          { code: 'SH', name: 'Schleswig-Holstein' },
          { code: 'TH', name: 'Thüringen' },
        ],
        [
          { code: 'AT-1', name: 'Burgenland' },
          { code: 'AT-2', name: 'Kärnten' },
          { code: 'AT-3', name: 'Niederösterreich' },
          { code: 'AT-4', name: 'Oberösterreich' },
          { code: 'AT-5', name: 'Salzburg' },
          { code: 'AT-6', name: 'Steiermark' },
          { code: 'AT-7', name: 'Tirol' },
          { code: 'AT-8', name: 'Vorarlberg' },
          { code: 'AT-9', name: 'Wien' },
        ],
        [
          { code: 'CH-AG', name: 'Aargau' },
          { code: 'CH-AR', name: 'Appenzell Ausserrhoden' },
          { code: 'CH-AI', name: 'Appenzell Innerrhoden' },
          { code: 'CH-BL', name: 'Basel-Landschaft' },
          { code: 'CH-BS', name: 'Basel-Stadt' },
          { code: 'CH-BE', name: 'Bern' },
          { code: 'CH-FR', name: 'Freiburg' },
          { code: 'CH-GE', name: 'Genf' },
          { code: 'CH-GL', name: 'Glarus' },
          { code: 'CH-GR', name: 'Graubünden' },
          { code: 'CH-JU', name: 'Jura' },
          { code: 'CH-LU', name: 'Luzern' },
          { code: 'CH-NE', name: 'Neuenburg' },
          { code: 'CH-NW', name: 'Nidwalden' },
          { code: 'CH-OW', name: 'Obwalden' },
          { code: 'CH-SH', name: 'Schaffhausen' },
          { code: 'CH-SZ', name: 'Schwyz' },
          { code: 'CH-SO', name: 'Solothurn' },
          { code: 'CH-SG', name: 'St. Gallen' },
          { code: 'CH-TI', name: 'Tessin' },
          { code: 'CH-TG', name: 'Thurgau' },
          { code: 'CH-UR', name: 'Uri' },
          { code: 'CH-VD', name: 'Waadt' },
          { code: 'CH-VS', name: 'Wallis' },
          { code: 'CH-ZG', name: 'Zug' },
          { code: 'CH-ZH', name: 'Zürich' },
        ],
      ],
      countries: [
        { name: 'Deutschland', code: 'DE' },
        { name: 'Österreich', code: 'AUT' },
        { name: 'Schweiz', code: 'CHE' },
      ],
      selectedStates: [],
    }
  },
  computed: {
    address() {
      return this.modelValue ? this.modelValue : { country_code: 'DE' }
    },
  },
  created() {
    this.selectedStates = this.states.length > 0 ? this.states[0] : []
  },
  methods: {
    async setCity(val) {
      this.$emit('update:modelValue', { ...this.address, zip: val })
      if (val.length === 5) {
        this.cityIcon = 'fas fa-circle-notch fa-spin'
        const zipCodes = await this.$cms.request(
          this.$readItems('zip_code', {
            filter: {
              zip: {
                _eq: val,
              },
            },
          })
        )
        if (zipCodes.length) {
          const zipCode = zipCodes[0]
          this.address.city = zipCode.city
          this.address.state_code = zipCode.state_code
        }
        this.cityIcon = null
      }
    },
    updateStatesDropdown(val) {
      this.$emit('update:modelValue', { ...this.address, country_code: val })
      switch (val) {
        case 'DE':
          this.selectedStates = this.states[0]
          break
        case 'AUT':
          this.selectedStates = this.states[1]
          break
        case 'CHE':
          this.selectedStates = this.states[2]
          break
      }
      return this.selectedStates
    },
  },
}
</script>
